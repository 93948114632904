
/*######################################################################*/
/* base */
/*######################################################################*/

/* import */






/* variables */

$color-primary:red;
$color-secondary:blue;
$color-third:pink;

$col-xs:768px;
$col-sm:992px;  
$col-md:1200px;
$col-lg:1930px;


/* extends */
%font{font-family:'Montserrat', sans-serif;}
%font-medium{font-family:'Montserrat', sans-serif;font-weight:700;}
%font-bold{font-family:'Montserrat', sans-serif;font-weight:700;}




/* particials */






/*######################################################################*/
/* global presets */
/*######################################################################*/

//headings
h1,h2,h3,h4,h5,h6,p{
    font-weight:100;
    padding:0px;
    margin:0px;   
}

/* structure */
html{height:100%;} 
body{height:100%;
    width:100%;
    background-color:black;
    overflow-x: hidden;
    @extend %font;
    &.loaded{.loader_all{visibility: hidden;.loader{opacity:0;}}}     
    
-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body,html{
    padding:0px;margin:0px;
    font-size:10px;

    /* Futura */
    /*font-family: Futura, "futura-pt", sans-serif;*/

    /* sofia pro */
    font-family: sofia-pro, sans-serif;
    font-weight: 100;
    font-style: normal;

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:black;
    color:white;
}

//paragraphs
p{font-size:17px;line-height:28px;}

//btns




//overlay
 .overlay{position:absolute;z-index:10;left:0px;top:0px;width:100%;height:100%;background-color:rgba($color-primary,.8);}

//no gutters
.row-nogutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}


//angular
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

//animate
.animate_all{
-webkit-transition: all .2s ease-out 0s;
-moz-transition: all .2s ease-out 0s;
-o-transition: all .2s ease-out 0s;
transition: all .2s ease-out 0s;
}
.animate_slow{
-webkit-transition: all 1s ease-out 0s;
-moz-transition: all 1s ease-out 0s;
-o-transition: all 1s ease-out 0s;
transition: all 1s ease-out 0s;
}


//animate
@-webkit-keyframes arrowFade {
    0% {
        opacity:0;
        bottom:50px;
    }
    20% {
        opacity:1;
        bottom:30px;
    }
    80% {
        opacity:1;
        bottom:30px;
    }
    100% {
        opacity:0;
        bottom:10px;
    }
}
@-moz-keyframes arrowFade {
    0% {
        opacity:0;
        bottom:50px;
    }
    20% {
        opacity:1;
        bottom:30px;
    }
    80% {
        opacity:1;
        bottom:30px;
    }
    100% {
        opacity:0;
        bottom:10px;
    }
}


//components
.section{
background-color:white;
width:100%;
min-height:800px;
.inner{padding:50px;}
    
    //mobile 
    @media(max-width: $col-xs) {
           min-height:10px;
    }
    //desktop large 
    @media(min-width: $col-lg) {.container{min-height:1200px;} }
}

//width 100
.w100{width:100%;}

//forms
textarea, textarea.form-control, input.form-control, input[type=text], input[type=password], input[type=email], input[type=number], [type=text].form-control, [type=password].form-control, [type=email].form-control, [type=tel].form-control, [contenteditable].form-control
{color:black;
&::-webkit-input-placeholder {
   color:lighten(black,60%);
}

&:-moz-placeholder { /* Firefox 18- */
   color:lighten(black,60%);
}

&::-moz-placeholder {  /* Firefox 19+ */
   color:lighten(black,60%);
}

&:-ms-input-placeholder {  
   color:lighten(black,60%);  
}
    &:focus{
        box-shadow: inset 0 -2px 0 white;
    }
    
}

//change media query for columns plugin
/* Maximum width of 600 pixels. */
@media screen and (max-width: 767px) {
	.full_width,
	.one_half,
	.one_third,
	.two_third,
	.one_fourth,
	.three_fourth,
	.one_fifth,
	.two_fifth,
	.three_fifth,
	.four_fifth,
	.one_sixth,
	.five_sixth {
		clear: both;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 1em;
        div{padding:0px !important;}
	}
}




/*######################################################################*/
/* structure */
/*######################################################################*/

   /* base */
   .app{
    width:100%;
    min-height:100vh;
}

/* reuse */
.btn{
background-color:red;
padding:10px 30px;
font-size:16px;
font-weight:500;
color:white;
display: inline-block;
}
.btn:hover{
    background-color:#ed6047;
    }

.logo{
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100%;
    z-index: 10;
}
.logo-gradient{
    position: absolute;
    top: 0px;
    left: -500px;
    height: 200px;
    width: 1000px;
    background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,0));
    z-index: 6;
    transform-origin: center top 0;
    transform: rotate(-22deg);
}
/* small desktop */
@media only screen and (max-width: 1200px) {
    .logo img{
        width:210px;
    }
}

/* mobile devices */
@media only screen and (max-width: 600px) {
}

// scroll header popout
@keyframes menuTopScroll {
    from {top:-100px;}
    to {top:0px;}
}

body{
    overflow-x: hidden;
    
    .navbar{
        &.navbar-fixed-top{position:absolute;} 
    }
    
    &.scroll{
        .navbar-brand{
            img{width:40px;}
            &.scrollhide{display:none;}
            &.scrollshow{display:inline-block;}
        }
        .navbar-nav{padding-top:10px;li{a{color:black;}}}
        .icon-bar {background-color:black;}
       
        .navbar{
           min-height: 70px;
           background-color:white;
           border-bottom:solid 1px darken(white,10%);
            &.navbar-fixed-top{
            position:fixed;
            animation-name: menuTopScroll;
            animation-duration: 1s;
           } 
        li{a{
            &.active{border-bottom:solid 3px $color-primary;}
            &.boxed{color:white;}
            }}    
        }
        .navbar-collapse{background-color:transparent;}
        
        //mobile
        @media(max-width: $col-xs) {
            .navbar-brand{img{width:56px;}}
        }
        
    }
    
}


//footer

.footer_all{
    padding:50px 20px;
    color:white;
    text-align: center;

    a{
        font-weight: 600;
        font-size: 14px;
        color:#dcdcdc;
        text-decoration: none;
        position: relative;
        z-index: 30;
    }
    a:hover{
        color:white;
    }
    
    //mobile
    @media(max-width: $col-xs) {
        text-align: center;
    }
    
    
}








/*######################################################################*/
/* pages */
/*######################################################################*/




/*#################################*/
/* hompage */
/*#################################*/



.home_page{

/* header */
.header_all{
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.header_all .holder{
    max-width: 1200px;
    margin:0px auto;
    position: relative;
    height:100%;
}
.header_all .image{
    height:100%;
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    background-image: url(../images/gallery/IMG_7368.jpg);
    background-position: center center;
    background-size: cover;
    z-index: 5;
}
.header_all .gradient{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 60%;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
    z-index: 6;
}
.header_all .logo-gradient{
    position: absolute;
    top: 0px;
    left: -500px;
    height: 200px;
    width: 1000px;
    background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,0));
    z-index: 6;
    transform-origin: center top 0;
    transform: rotate(-22deg);
}
.header_all .text{
    position: absolute;
    bottom:200px;
    left:0px;
    z-index: 10;
}
.header_all .text h3{
    font-weight: 400;
    font-size: 16px;
    color:white;
    margin-bottom:-22px;
}
.header_all .text h1{
    font-weight: 200;
    font-size: 75px;
    color:white;
}
.header_all .text p{
    margin-top: -15px;
    font-size: 40px;
    color:#dcdcdc;
}
.header_all .text .btn{
    margin-top:20px;
}

/* small desktop */
@media only screen and (max-width: 1200px) {
    .header_all{
        height:70vh;
    }
    .header_all .holder{
        margin-left:20px;
        margin-right:20px;
    }
    .header_all .text {
        bottom:90px;
    }
    .header_all .text h3{
        font-size: 14px;
        margin-bottom:-16px;
    }
    .header_all .text h1{
        font-weight: 300;
        font-size: 50px;
    }
    .header_all .text p{
        font-weight: 200;
        margin-top: -15px;
        font-size: 25px;
    }
    .header_all .text .btn{
        margin-top:20px;
    }
}

/* mobile devices */
@media only screen and (max-width: 600px) {
    .header_all{

    }
}


/* grid */
.grid_all{
    position: relative;
    z-index:20;
    max-width:1200px;
    margin:0px auto;
    margin-top:-100px;
}
.grid_all .collection{
    width:380px;
    height:240px;
    position: relative;
    margin-bottom:30px;
    float:left;
    margin-right:30px;
    box-sizing: border-box;
    border:3px solid rgba(255,255,255,0);
}
.grid_all .collection:hover{
    border:3px solid rgba(255,255,255,.3);
}
.grid_all .desktop-end{
    margin-right:0px;
}
.grid_all .collection .image{
    height:100%;
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    background-image: url(../images/gallery/IMG_7368.jpg);
    background-position: center center;
    background-size: cover;
    z-index: 5;
}
.grid_all .collection .gradient{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 80%;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.7));
    z-index: 6;
}
.grid_all .collection .text{
    position: absolute;
    bottom:10px;
    left:10px;
    z-index: 10;
}
.grid_all .collection .text h2{
    font-weight: 300;
    font-size: 30px;
    color:white;
}
.grid_all .collection .text p{
    margin-top: -5px;
    font-weight: 300;
    font-size: 20px;
    color:#dcdcdc;
}

/* small desktop */
@media only screen and (max-width: 1200px) {
    .grid_all{
        margin-top:-40px;
        margin-left:20px;
        margin-right:20px;
    }
    .grid_all .collection{
        margin:0px auto;
        margin-bottom:10px;
        width:49%;
        height:280px;
        margin-right:1%;
    }
    .grid_all .tablet-end{
        margin-right:0px;
    }
}

/* mobile devices */
@media only screen and (max-width: 600px) {
    .grid_all{

    }
    .grid_all .collection{
        margin-right:0px;
        float:none;
        width:100%;
    }
    
}



/* header-viewpage */
.header-viewpage_all{
    height: 80vh;
    position: relative;
    overflow: hidden;
}
.header-viewpage_all .holder{
    max-width: 1200px;
    margin:0px auto;
    position: relative;
    height:100%;
}
.header-viewpage_all .image{
    height:100%;
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    background-image: url(../images/gallery/IMG_7368.jpg);
    background-position: center center;
    background-size: cover;
    z-index: 5;
}

.header-viewpage_all .gradient{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 90%;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
    z-index: 6;
}
.header-viewpage_all .text{
    position: absolute;
    bottom:200px;
    left:0px;
    z-index: 10;
    text-align:center;
    width:100%;
}
.header-viewpage_all .text h1{
    font-weight: 200;
    font-size: 75px;
    color:white;
}
.header-viewpage_all .text p{
    margin-top: -15px;
    font-size: 40px;
    color:#dcdcdc;
}
.header-viewpage_all .text .back{
    margin-top:30px;
}
.header-viewpage_all .text .back a{
    font-weight: 600;
    font-size: 14px;
    color:#dcdcdc;
    text-decoration: none;
    position: relative;
    z-index: 30;
}
.header-viewpage_all .text .back a:hover{
    color:white;
}

/* small desktop */
@media only screen and (max-width: 1200px) {
    .header-viewpage_all{
        height:50vh;
    }
    .header-viewpage_all .holder{
        margin-left:20px;
        margin-right:20px;
    }
    .header-viewpage_all .text {
        bottom:90px;
    }
    .header-viewpage_all .text h1{
        font-weight: 300;
        font-size: 50px;
    }
    .header-viewpage_all .text p{
        font-weight: 200;
        margin-top: -15px;
        font-size: 25px;
    }
}

/* mobile devices */
@media only screen and (max-width: 600px) {
    .header_all{

    }
}


/* gallery */
.gallery_all{
    max-width:1200px;
    margin:0px auto;
    margin-top:-100px;
    position: relative;
    z-index: 20;
}
.gallery_all .image{
    margin-bottom:30px;
}
.gallery_all .image img{
    width:100%;
}

/* small desktop */
@media only screen and (max-width: 1200px) {
    .gallery_all{
        margin-left:20px;       
        margin-right:20px;       
        margin-top:-50px;
    }
    .gallery_all .image{
        margin-bottom:10px;
    }
}

/* mobile devices */
@media only screen and (max-width: 600px) {
    .gallery_all{

    }
}



    
   
 
}






/*#################################*/
/* gallery page */
/*#################################*/



.gallery_page{
  
    


/* header-viewpage */
.header-viewpage_all{
    height: 80vh;
    position: relative;
    overflow: hidden;
}
.header-viewpage_all .holder{
    max-width: 1200px;
    margin:0px auto;
    position: relative;
    height:100%;
}
.header-viewpage_all .image{
    height:100%;
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    background-image: url(../images/gallery/IMG_7368.jpg);
    background-position: center center;
    background-size: cover;
    z-index: 5;
}
.header-viewpage_all .gradient{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 90%;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
    z-index: 6;
}
.header-viewpage_all .text{
    position: absolute;
    bottom:200px;
    left:0px;
    z-index: 10;
    text-align:center;
    width:100%;
}
.header-viewpage_all .text h1{
    font-weight: 200;
    font-size: 75px;
    color:white;
}
.header-viewpage_all .text p{
    margin-top: -15px;
    font-size: 40px;
    color:#dcdcdc;
}
.header-viewpage_all .text .back{
    margin-top:30px;
}
.header-viewpage_all .text .back a{
    font-weight: 600;
    font-size: 14px;
    color:#dcdcdc;
    text-decoration: none;
    position: relative;
    z-index: 30;
}
.header-viewpage_all .text .back a:hover{
    color:white;
}

/* small desktop */
@media only screen and (max-width: 1200px) {
    .header-viewpage_all{
        height:50vh;
    }
    .header-viewpage_all .holder{
        margin-left:20px;
        margin-right:20px;
    }
    .header-viewpage_all .text {
        bottom:90px;
    }
    .header-viewpage_all .text h1{
        font-weight: 300;
        font-size: 50px;
    }
    .header-viewpage_all .text p{
        font-weight: 200;
        margin-top: -15px;
        font-size: 25px;
    }
}

/* mobile devices */
@media only screen and (max-width: 600px) {
    .header_all{

    }
}


/* gallery */
.gallery_all{
    max-width:1200px;
    margin:0px auto;
    margin-top:-100px;
    position: relative;
    z-index: 20;
}
.gallery_all .image{
    margin-bottom:30px;
    .download{
        a{
            color:white;
            text-decoration: none;
            font-size: 16px;
            font-weight:500;
            i{
                margin-right:5px;
            }
        }
    }
}
.gallery_all .image img{
    width:100%;
}

/* small desktop */
@media only screen and (max-width: 1200px) {
    .gallery_all{
        margin-left:20px;       
        margin-right:20px;       
        margin-top:-50px;
    }
    .gallery_all .image{
        margin-bottom:100px;
    }
}

/* mobile devices */
@media only screen and (max-width: 600px) {
    .gallery_all{

    }
}




    
    
}




/*#################################*/
/* locations */
/*#################################*/



.locations_page{
    
    
    
    
    
   
    
    .portal_all{
        background-size: cover; 
        background-position: center center;
        background-repeat: no-repeat;
        background-image:url(../images/site/home-banner.jpg); 
        height:300px;
        position: relative;
        .overlay{position:absolute;z-index:10;left:0px;top:0px;width:100%;height:100%;background-color:rgba(darken(black,20%),.5);}
        .content{
            position:absolute;z-index:20;top:50%;left:50%;transform: translate(-50%,-50%);
            text-align:center;width:800px;
            h1{
                color:white;font-size:32px;
                .largetxt{font-size:40px;}
                text-shadow: 2px 2px 2px rgba(black,.5);
            }
        }

        
        //mobile
        @media(max-width: $col-xs) {
            height:300px;
            .content{
                img{width:100%;}
                .logo{
                width:200px;
                height:200px;
            }
                
            }
            
        } 
    }

    .locations_all{ 
        padding:50px 0px;
        position: relative;
        z-index:10;
        .maintitle{h2{}}
        .box{
            padding:1px;
            margin-bottom:20px; 
            box-shadow: 0px 0px 9px rgba(black,.3);
            text-align:center;
            background-color:white;
            .image{
                background-image:url(../images/pages/home/melbourne-city.jpg);
                background-size:cover;height:250px;
            }
            .text{
                text-align:left;
                .title{padding-top:20px;h2{font-size:21px;color:lighten(black,40%);margin-bottom:0px;text-align:left;}}
                .location{p{font-size:12px;color:lighten(black,50%);font-style:italic;}}
                .description{p{font-size:14px;color:lighten(black,10%);}}
                .button{padding-bottom:20px;}
            }
        }
        
        //mobile
        @media(max-width: $col-xs) {
            .box{
                .text{padding:10px;}
            }
        }
        
    }
    
    
    
    
    
    
 
}







/*#################################*/
/* location Details */
/*#################################*/




.locationdetails_page{ 
    
 
.portalinside_all{
 position:relative;
    background-size:cover;
    background-position:center center;
    background-image:url(../images/site/home-banner.jpg); 
    .container{position:relative;min-height:300px;}
    .content{
        i{margin-right:10px;color:$color-primary;}
        position:absolute;
        z-index: 20;
        bottom:0px;
        left:20px;
        padding:15px;
        .image{float:left;width:100px;height:100px;background-size:contain;background-repeat: no-repeat;}
        .text{padding:20px 30px 0px 20px;
            h1{font-size:38px;margin-bottom:5px;color:white;}
            p{color:white;}
        }
    }
    .overlay{background-color:rgba(black,.5);}
    
    //mobile
    @media(max-width: $col-xs) {
        .content{text-align:center;
            h1{font-size:38px;}
            width:100%;position:absolute;left:0px;bottom:0px;
            .image{float:none;margin:0px auto;width:70px;height:70px;}
            .pull-left{float:none !important;}
            .pull-right{float:none !important;}
        }
        .container{min-height:250px;}  
    } 
    
}     

.columns_all{
    padding:80px 0px;
    .content{padding-right:80px;}
    .sidebar{}
    
    //mobile
        @media(max-width: $col-xs) {
            padding:20px 0px;
            .content{padding-right:15px;}
           }  
    
    }    

    
.about_all{
    padding:0px;
     .container{position:relative;}
        h2{font-size:2.3em;width:400px;@extend %font-medium;}
        h3{font-size:20px;width:400px;margin-bottom:20px;@extend %font-medium;}
        p{font-size:1.3em;line-height:1.8em;}
        .image{height:470px;background-size:cover;background-position: center center;} 
       
        //mobile
        @media(max-width: $col-xs) {
            h2{width:100%;}
            h3{width:100%;}
           }    
    }    
    

    
    
    .map_all{
        height:300px;
        position:relative; 
        
    }
    
    
    .services_all{
        padding:20px;
        margin:40px 0px 20px 0px;
        border:solid 1px lighten(black,95%);
        background-color:lighten(black,99%);
        border-radius:0px;
        .box{padding:20px 0px;padding-left:30px;
            i{margin-right:10px;color:$color-primary;}
        } 
        
        //mobile
        @media(max-width: $col-xs) {
            .box{padding:2px;padding-left:30px;}
        }
        
    }
    
    
    .deals_all{
        .link{margin-top:10px;} 
    } 
    
    
    .info_all{
        padding:20px 0px;
        i{margin-right:10px;color:$color-primary;}
        .address{line-height:25px;}
        .phone{margin-top:15px;}
        .website{margin-top:10px;}
        
        
        //mobile
        @media(max-width: $col-xs) {
            text-align: center;
        }
        
    }   
    
    .hours_all{
        
        
        //mobile
        @media(max-width: $col-xs) {
            text-align: center;
        }
        
    }
    
    
    .contact_all{
        padding:20px 0px;
        i{margin-right:10px;color:$color-primary;}
        .contact{position:relative;}
        .imageholder{
                
            .image{
                width:100px;
                height:100px;
                background-color:lighten(black,95%);
                background-size:cover;
                background-position:center center;
                border-radius:100px;
            }
        }
        .text{
            .description{margin-top:30px;p{font-size:15px;line-height:22px;}}
        }
        
        //mobile
        @media(max-width: $col-xs) {
            .imageholder{
                text-align: center;
                .image{display: inline-block;}
            }
            .text{
                text-align: center;
                .phone{}
                .email{}
                .description{text-align: left;}
            }
        }
    }
    
    .gallery_all{
        margin-bottom:20px;
        h2{text-align:left;color:lighten(black,30%);}
        .image{
        background-size:cover;
        background-position:center center;
        height:400px; 
        }
        .holder{position:relative;}
        .slick-next,.slick-prev{position:absolute;top:50%;z-index:5;transform: translateY(-50%)}
        .slick-prev:before, .slick-next:before{
        color:rgba(white,1);font-size: 45px;
        } 
        .slick-next{right:40px;}
        .slick-prev{left:20px;}
        //.slick-slide{@extend .animate_all;opacity:.5;width:1000px;}
        //.slick-current{opacity:1;}
        
        //mobile
        @media(max-width: $col-xs) {
            .image{height:300px;}
            h2{font-size:20px;}
           }
    }
    
    
    .dealers_all{
    padding:50px 0px;
    background-color:lighten(black,97%);
    a,a:hover{text-decoration: none;}
    .box{
            @extend .animate_all;
            padding:1px;
            margin-bottom:20px;
            box-shadow: 0px 0px 9px rgba(black,.3);
            text-align:center;
            background-color:white;
            .image{
                background-image:url(../images/pages/home/melbourne-city.jpg);
                background-size:cover;height:250px;
            }
            .title{padding-top:20px;h2{font-size:21px;color:lighten(black,40%);margin-bottom:20px;}}
            .button{padding-bottom:20px;}
            &:hover{transform: scale(1.04);}
        }  
        
    //mobile
        @media(max-width: $col-xs) {
            padding:20px;
            .box{
                margin-bottom:10px;
                .image{height:100px;}
            }
        }    
        
        
    }
    
    
}








/*#################################*/
/* cars */
/*#################################*/



.cars_page{
    
    
    .portal_all{
        background-size: cover; 
        background-position: center center;
        background-repeat: no-repeat;
        background-image:url(../images/site/home-banner.jpg); 
        height:300px;
        position: relative;
        .overlay{position:absolute;z-index:10;left:0px;top:0px;width:100%;height:100%;background-color:rgba(darken(black,20%),.5);}
        .content{
            position:absolute;z-index:20;top:50%;left:50%;transform: translate(-50%,-50%);
            text-align:center;width:800px;
            h1{
                color:white;font-size:32px;
                .largetxt{font-size:40px;}
                text-shadow: 2px 2px 2px rgba(black,.5);
            }
        }

        
        //mobile
        @media(max-width: $col-xs) {
            height:300px;
            .content{
                img{width:100%;}
                .logo{
                width:200px;
                height:200px;
            }
                
            }
            
        } 
    }

    .cars_all{ 
        padding:50px 0px;
        position: relative;
        z-index:10;
        .maintitle{h2{}}
        .box{
            padding:4px;
            box-shadow: 0px 0px 9px rgba(black,.3);
            text-align:center;
            background-color:white;
            .image{
                position: relative;
                background-image:url(../images/pages/home/car-front.jpg);
                background-size:cover;height:250px;
                .imagehover{
                @extend .animate_all;
                opacity: 0;
                position:absolute;
                top:0px;left:0px;height:100%;width:100%;z-index:5;
                background-image:url(../images/pages/home/car-side.jpg);
                background-size:cover;height:250px;
                }
                
            }
            .title{padding-top:20px;h2{font-size:21px;color:lighten(black,40%);margin-bottom:20px;}}
            .features{
                .line{padding:5px 30px;text-align: left;}
                .light{}
                .dark{background-color:lighten($color-secondary,30%);}
            }
            .button{border-top:solid 1px darken(white,10%);padding:10px 0px;}
            &:hover{.image{.imagehover{opacity: 1;}}}
        }
        
        
        //mobile
        @media(max-width: $col-xs) {
            .box{margin-bottom:10px;}
        }
        
    }
    
    
    
    
    
    
 
}





/*#################################*/
/* Car Details */
/*#################################*/




.cardetails_page{ 
    
 
.portalinside_all{
 position:relative;
    background-size:cover;
    background-position:bottom center;
    background-image:url(../images/site/home-banner.jpg); 
    .container{position:relative;min-height:350px;}
    .content{
        i{margin-right:10px;color:$color-primary;}
        position:absolute;
        z-index: 20;
        bottom:50%;
        left:50%;
        transform:translate(-50%,50%);
        .image{float:left;width:100px;height:100px;background-size:contain;background-repeat: no-repeat;}
        .text{padding:20px 30px 0px 20px;
            h1{font-size:38px;margin-bottom:5px;color:white;text-shadow: 0px 0px 5px rgba(black,.8);}
        }
    }
    .overlay{background-color:rgba(black,.5);}
    
    //mobile
    @media(max-width: $col-xs) {
        .content{text-align:center;
            h1{font-size:38px;}
            width:auto;margin:10px;padding:5px;position:absolute;left:0px;
            .image{float:none;margin:0px auto;width:70px;height:70px;}
            .pull-left{float:none !important;}
            .pull-right{float:none !important;}
        }
        .container{min-height:200px;}  
    } 
    
}     

.columns_all{
    padding:20px 0px;
    .content{padding-right:80px;}
    .sidebar{}
    
    //mobile
        @media(max-width: $col-xs) {
            padding:20px 0px;
            .content{padding-right:15px;}
           }  
    
    }    

    
.about_all{
    padding-top:20px;
     .container{position:relative;}
        h2{font-size:2.3em;@extend %font-medium;}
        h3{font-size:20px;margin-bottom:20px;@extend %font-medium;}
        p{font-size:1.3em;line-height:1.8em;}
        .image{background-image:url(../images/site/home-banner.jpg);background-size:cover;height:300px;margin-bottom:20px;}  
       
        //mobile
        @media(max-width: $col-xs) {
            h2{width:100%;}
            h3{width:100%;}
           }    
    }    
    

    
    
    .map_all{
        height:300px;
        position:relative; 
        
    }
    
    
    .features_all{
        padding:20px 0px;
        h2{margin-bottom:10px;}
        border-radius:10px;
        .box{
            //padding:20px 0px;
            .line{padding:5px 30px;text-align: left;}
                .dark{background-color:lighten($color-secondary,30%);}
            i{margin-right:10px;color:$color-primary;}
        } 
        
    }
    
    
    .deals_all{
        .link{margin-top:10px;} 
    } 
    
    
    .info_all{
        padding:20px 0px;
        i{margin-right:10px;color:$color-primary;}
        .address{line-height:25px;}
        .phone{margin-top:15px;}
        .website{margin-top:10px;}
    }
    
    
    .contact_all{
        padding:20px 0px;
        i{margin-right:10px;color:$color-primary;}
        .contact{position:relative;}
        .imageholder{
                
            .image{
                width:100px;
                height:100px;
                background-color:lighten(black,95%);
                background-size:cover;
                background-position:center center;
                border-radius:100px;
            }
        } 
    }
    
    .pricetable_all{
        padding-top:20px;
        .table{
            thead{@extend %font-bold;}
            .small{@extend %font-bold;color:$color-primary;}
        }
    }
    
    .insurance_all{
        .box{
            min-height:300px;
            border:solid 1px darken(white,5%);
            padding:15px 15px;
            background-color:darken(white,2%);
            h4{text-align:center;margin-bottom:20px;@extend %font-bold;}
            &.gambler{background-color:darken(white,2%);}
            &.fullmonty{background-color:darken($color-secondary,0%);color:white;h4{color:white;}}
        }
    }
    
    .gallery_all{
        h2{text-align:left;color:lighten(black,30%);}
        .image{
        background-size:cover;
        background-position:center center;
        height:400px; 
        }
        .holder{position:relative;}
        .slick-next,.slick-prev{position:absolute;top:50%;z-index:5;transform: translateY(-50%)}
        .slick-prev:before, .slick-next:before{
        color:rgba(white,1);font-size: 45px;
        } 
        .slick-next{right:40px;}
        .slick-prev{left:20px;}
        //.slick-slide{@extend .animate_all;opacity:.5;width:1000px;}
        //.slick-current{opacity:1;}
        
        //mobile
        @media(max-width: $col-xs) {
            .image{height:300px;}
            h2{font-size:20px;}
           }
    }
    
    
    
    .bookingform_all{
        .form{
            margin-top:-200px;
            position: relative;
            z-index: 20;
        width: 355px;
        background-color:$color-secondary;
        .header{background-color:darken($color-secondary,10%);font-size:25px;@extend %font-bold;text-align:center;text-transform: uppercase;padding:22px;color:white;}
        .body{
            padding:22px;
            .form-control{border-radius:0px;padding:17px 12px;font-size:14px;background-color:white;}
            .form-group {margin-bottom: 4px;}
            .input-group-addon{background-color: white;border-radius: 0px;}
            .text-error{line-height:24px;font-size:14px;}
            .note{padding: 10px 10px;}
            select{height:auto;&.form-control{padding:11px 12px;}}
            .col-left{padding-right:2px;}
            .col-right{padding-left:2px;}
        }
    }
        
    //mobile
    @media(max-width: $col-xs) {
        .form{width:100%;margin-top:0px;}        
    }    
        
    }
    
    
    
    .priceform_all{
        margin-top:20px;
        .form{
            position: relative;
            z-index: 20;
        width: 355px;
        background-color:$color-primary;
        .header{background-color:darken($color-primary,10%);font-size:25px;@extend %font-bold;text-align:center;text-transform: uppercase;padding:22px;color:white;}
        .body{
            padding:22px;
            .form-control{border-radius:0px;padding:17px 12px;font-size:14px;background-color:white;}
            .form-group {margin-bottom: 4px;}
            .input-group-addon{background-color: white;border-radius: 0px;}
            .text-error{line-height:24px;font-size:14px;color:red;}
            .note{padding: 10px 10px;}
            select{height:auto;&.form-control{padding:11px 12px;}}
            .col-left{padding-right:2px;}
            .col-right{padding-left:2px;}
            .result{
                color:white;font-weight:700;margin-top:20px;
                .days{font-size:20px;}
                .price{font-size:30px;}
            }
        }
    }
        
    //mobile
    @media(max-width: $col-xs) {
        .form{width:100%;margin-top:0px;}        
    }    
        
    }    
    
    
    
    
    
    .cars_all{
    padding:50px 0px;
    background-color:lighten(black,97%);
    a,a:hover{text-decoration: none;}    
    .box{
            padding:4px;
            box-shadow: 0px 0px 9px rgba(black,.3);
            text-align:center;
            background-color:white;
            .image{
                position: relative;
                background-image:url(../images/pages/home/car-front.jpg);
                background-size:cover;height:250px;
                background-position: center center;
                .imagehover{
                @extend .animate_all;
                opacity: 0;
                position:absolute;
                top:0px;left:0px;height:100%;width:100%;z-index:5;
                background-image:url(../images/pages/home/car-side.jpg);
                background-size:cover;
                background-position: center center;
                }
                
            }
            .title{padding-top:20px;h2{font-size:21px;color:lighten(black,40%);margin-bottom:20px;}}
            .features{
                .line{padding:5px 30px;text-align: left;}
                .light{}
                .dark{background-color:lighten($color-secondary,30%);}
            }
            .button{border-top:solid 1px darken(white,10%);padding:10px 0px;}
            &:hover{.image{.imagehover{opacity: 1;}}}
        } 
    
    //mobile
        @media(max-width: $col-xs) {
            padding:20px 0px;
            .box{
                margin-bottom:10px;
                .image{height:150px;}
                .title{padding-top:5px;h2{font-size:14px;margin-bottom:10px;}}
                .features{
                    .line{padding:2px 5px;}
                }
            }
            
        }
    
    }
    
    
}





/*#################################*/
/* FAQ */
/*#################################*/



.faq_page{
    
    .portalinside_all{
        position: relative;
        background-size: cover; 
        background-position: center center;
        background-repeat: no-repeat;
        height:300px; background-color:darken(white,4%);
        .overlay{background-color:rgba(darken(black,20%),.5);}
        
        //mobile
        @media(max-width: $col-xs) {
            height:200px;
        }    
        
    }
    
    
    
    
        
.questions_all{
    padding:35px 0px;    
    text-align:left; 
    .questionlink{margin-bottom:5px;font-size:16px;font-weight:800;}
    .question{
        margin:30px 0px;
        h2{font-size:24px;margin-bottom:10px;text-align:left;}
        p{
        font-size:16px;line-height:30px;
        &.large{font-size:25px;}
        }
    }
    
    }  
    
      
    
    
    
    
}





/*#################################*/
/* news */
/*#################################*/



.news_page{
    
    
    
    
    
   
    
    .portal_all{
        background-size: cover; 
        background-position: center center;
        background-repeat: no-repeat;
        background-image:url(../images/site/home-banner.jpg); 
        height:300px;
        position: relative;
        .overlay{position:absolute;z-index:10;left:0px;top:0px;width:100%;height:100%;background-color:rgba(darken(black,20%),.5);}
        .content{
            position:absolute;z-index:20;top:50%;left:50%;transform: translate(-50%,-50%);
            text-align:center;width:800px;
            h1{
                color:white;font-size:32px;
                .largetxt{font-size:40px;}
                text-shadow: 2px 2px 2px rgba(black,.5);
            }
        }

        
        //mobile
        @media(max-width: $col-xs) {
            height:300px;
            .content{
                img{width:100%;}
                .logo{
                width:200px;
                height:200px;
            }
                
            }
            
        } 
    }

    .news_all{ 
        padding:50px 0px;
        position: relative;
        z-index:10;
        .maintitle{h2{}}
        .box{
            padding:1px;
            margin-bottom:20px; 
            box-shadow: 0px 0px 9px rgba(black,.3);
            text-align:center;
            background-color:white;
            .image{
                background-image:url(../images/pages/home/melbourne-city.jpg);
                background-size:cover;height:250px;
            }
            .text{
                text-align:left;
                .title{padding-top:20px;h2{font-size:21px;color:lighten(black,40%);margin-bottom:0px;text-align:left;}}
                .location{p{font-size:12px;color:lighten(black,50%);font-style:italic;}}
                .description{p{font-size:14px;color:lighten(black,10%);}}
                .button{padding-bottom:20px;}
            }
        }
        
        //mobile
        @media(max-width: $col-xs) {
            .box{
                .text{padding:10px;}
            }
        }
        
    }
    
    
    
    
    
    
 
}




/*#################################*/
/* custompage */
/*#################################*/



.custom_page{
    
    .portalinside_all{
        position: relative;
        background-size: cover; 
        background-position: center center;
        background-repeat: no-repeat;
        height:300px; background-color:darken(white,4%);margin-bottom:50px;
        .overlay{background-color:rgba(darken(black,20%),.5);}
        
        //mobile
        @media(max-width: $col-xs) {
            height:200px;
        }    
        
    }
    
    
    .content_all{
       h2{font-size:35px;@extend %font-medium;margin-bottom:40px;color:$color-primary;text-align:center;}
       h3{font-size:40px;color:darken(white,55%);font-weight:100;line-height:57px;margin-bottom:40px;
         .highlight{border-bottom:solid 2px $color-primary;padding-bottom:6px;color:$color-primary;} 
        }
        h4{font-size:18px;margin-bottom:30px;}
        ul{font-size:15px;}
        
     //mobile
        @media(max-width: $col-xs) {
            p{font-size:14px;}
            h2{font-size:25px;} 
            h3{font-size:25px;}
            img[class^='wp-image'], img[class*=' wp-image']{width:100%;height:auto;}
        }    
        
        
    }
    
    
}



    



